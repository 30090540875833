import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Page from "../components/Page";
import ResourceCard from "../components/Resources/ResourceCard";

export default function Resurser({ data }) {
  return (
    <>
      <Page
        title="Erbjudanden för studenten - Studentmottagningen"
        description="Här hittar du flera grymma erbjudanden på saker som behövs inför studenten. Vi har samlat alla erbjudanden för dig som förälder eller student."
      >

        <div className="bg-white ">
          <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 m-auto">
            <section className="bg-white  mb-8">
              <div className="gap-16 items-center mx-auto max-w-screen-xl lg:grid lg:grid-cols-2">
                <div className="font-light text-gray-500 sm:text-lg ">
                  <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                    Erbjudanden för studenten
                  </h1>
                  <p className="mb-4">
                  När det är dags att ta studenten är det många saker som behöver ordnas och köpas. Från studentmössan och inbjudningskort till mat och studentkläder. Det kan lätt bli en dyr affär och det är därför viktigt att utnyttja alla möjliga erbjudanden som finns tillgängliga.
                  </p>
                  <p className="mb-4">
                    Nedan hittar du de bästa erbjudandena på allt från <span className="font-normal text-gray-600">inbjudningskort, studentkläder, studentplakat, studentresor och mycket mer</span>.
                  </p>
                  <p>
                    Vissa av länkarna är affiliatelänkar. Det ger oss en liten provision, men kostar inget extra för dig och du hjälper oss att kunna fortsätta att erbjuda gratis innehåll.
                  </p>
                </div>
                <div className="mt-8">
                  <GatsbyImage
                    image={data.image.childImageSharp.gatsbyImageData}
                    backgroundColor="white"
                    alt="studenthattar i luften"
                    loading="eager"
                    className="w-full rounded-lg mb-4 lg:mb-10"
                  />
                </div>
              </div>
            </section>
            <section class="bg-white">
              <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                  <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">
                    Inbjudningskort
                  </h2>
                  <p class="font-light text-gray-500 sm:text-xl ">
                  Inbjudningskort är en viktig detalj för att informera gäster om studenten. Här hittar du erbjudanden på inbjudningskort till studenten.
                  </p>
                </div>
                <div class="grid gap-8 lg:grid-cols-2">
                    <ResourceCard 
                        type="Inbjudningskort"
                        title="SmartPhoto"
                        text="På Smartphoto så kan du skapa dina egna inbjudningskort till studenten. Du kan välja mellan flera olika mallar. De erbjuder rabatt på upp till 25 % på allt."
                        url="https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/fotokort"
                    />
                    <ResourceCard
                        type="Inbjudningskort"
                        title="Önskefoto"
                        text="På Önskefoto så kan du skapa dina egna inbjudningskort till studenten. De erbjuder också just nu 25 % rabatt på kort."
                        url="https://www.onskefoto.se/fotokort/inbjudningskort-fest"
                    />

                </div>
              </div>
            </section>
            <section class="bg-white">
              <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                  <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">
                    Studentmössa
                  </h2>
                  <p class="font-light text-gray-500 sm:text-xl ">
                    Studentmössan är kanske den viktigaste detaljen för studenten. Nedan hittar du erbjudanden på studentmössor.
                  </p>
                </div>
                <div class="grid gap-8 lg:grid-cols-2">
                    <ResourceCard
                        type="Studentmössa"
                        title="ABC-Gruppen"
                        text="ABC-Gruppen erbjuder ett stort urval av studentmössor till studenten."
                        url="https://www.abcgruppen.se/"
                    />
                    <ResourceCard
                        type="Studentmössa"
                        title="CL. Seivert"
                        text="CL. Seivert erbjuder också ett stort urval av studentmössor till studenten."
                        url="https://clseifert.com/"
                    />
                </div>
              </div>
            </section>
            <section class="bg-white">
              <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                  <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">
                    Studentplakat
                  </h2>
                  <p class="font-light text-gray-500 sm:text-xl ">
                    Studentplakat eller studentskylt är vanliga att ta med sig på utspringet för att fira ditt barn. Nedan hittar du erbjudanden på studentplakat.
                  </p>
                </div>
                <div class="grid gap-8 lg:grid-cols-2">
                    <ResourceCard
                        type="Studentplakat"
                        title="SmartPhoto"
                        text="På Smartphoto så kan du också skapa dina egna studentplakat. De erbjuder rabatt på upp till 25 % på allt."
                        url="https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/studenten/studentskylt"
                    />
                    <ResourceCard
                        type="Studentplakat"
                        title="Partykungen"
                        text="Partykungen erbjuder också möjligheten att designa studentplakat till studenten. Till bra priser dessutom beroende på vilken design du väljer."
                        url="https://click.adrecord.com/?c=155221&p=198&url=https://www.partykungen.se/designa-din-egen-studentskylt-43649.html"
                    />
                </div>
              </div>
            </section>
            <section class="bg-white">
              <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                  <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 ">
                    Studentkläder och studentaccessoarer
                  </h2>
                  <p class="font-light text-gray-500 sm:text-xl ">
                    Nedan hittar du erbjudanden för både studentkläder och studentaccessoarer, för killar och tjejer.
                  </p>
                </div>
                <div class="grid gap-8 lg:grid-cols-2">
                    <ResourceCard
                        type="Studentklänning"
                        title="Bubbleroom"
                        text="Bubbleroom erbjuder ett stort utbud av studentklänningar till studenten."
                        url="https://pin.bubbleroom.se/t/t?a=462891025&as=1773817588&t=2&tk=1&url=https://www.bubbleroom.se/sv/kl%C3%A4der/kvinna/studentkl%C3%A4nningar"
                    />
                    <ResourceCard
                        type="Studentkläder"
                        title="Åhlens"
                        text="Åhlens erbjuder ett stort utbud av studentkläder och andra studentaccessoarer till studenten. Du som är medlem får dessutom 10 % rabatt."
                        url="https://in.ahlens.se/t/t?a=1136856392&as=1773817588&t=2&tk=1&"
                    />

                </div>
              </div>
            </section>

         

            <br />
            <br />
          </div>
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "studenthattar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
